body {
  margin: 0;
  font-family: "Lato", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply bg-slate-100 text-lg;
}

.hljs {
  @apply rounded-lg;
}

pre code.hljs {
  @apply !p-2.5 text-base;
}

.uli-toast {
  @apply bg-white w-full mb-0 !important;
}

.uli-toast-body {
  @apply text-black text-sm !important;
}

.Toastify__toast-container--bottom-left {
  @apply fixed left-0 bottom-0 w-96 p-6 space-y-2.5 !important;
}

#renderedMarkdown {
  @apply space-y-3 whitespace-normal;
}

#renderedMarkdown > ol {
  @apply list-decimal;
}

#renderedMarkdown > ul {
  @apply list-disc;
}

#renderedMarkdown > ol,
#renderedMarkdown > ul {
  @apply pl-10 space-y-0.5;
}

#renderedMarkdown > h1 {
  @apply text-2xl font-bold;
}
#renderedMarkdown > h2 {
  @apply text-xl font-bold;
}

#renderedMarkdown > table {
  @apply table-auto border border-slate-400;
}

#renderedMarkdown > table > thead > tr > th {
  @apply bg-slate-600 text-slate-100 font-bold p-2;
}

#renderedMarkdown > table > tbody > tr > td {
  @apply border border-slate-400 p-2;
}

#renderedMarkdown > details > summary {
  @apply cursor-pointer text-blue-600;
}
