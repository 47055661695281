.button {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  @apply capitalize border border-transparent transition ease-in-out tracking-wide rounded font-medium;
}
.button--primary {
  @apply text-white bg-primary;
}
.button--default {
  @apply text-gray-500 bg-gray-200 hover:bg-gray-300;
}
.button--outlined {
  @apply text-primary border border-primary bg-transparent hover:border-primary-light;
}
.button--success {
  @apply text-white bg-green-500 hover:bg-green-600;
}
.button--error {
  @apply text-white bg-red-500 hover:bg-red-600;
}
.button--sm {
  @apply p-2 text-xs;
}
.button--md {
  @apply px-3 py-2 text-sm;
}
.button--lg {
  @apply px-4 py-3 text-base;
}

:disabled {
  opacity: 0.4;
  cursor: default;
}
